<template>
  <div>
    <v-row class="mb-3" v-if="showTitle">
      <v-col class="d-flex align-center justify-space-between">
        <h3>貨箱資料</h3>
      </v-col>
    </v-row>

    <v-row>
      <v-col xl="6" lg="6" md="6" sm="12" cols="12" order-xl="1" order-lg="1" order-md="1" order="2">
        <v-row class="mt-0">
          <v-col class="pt-0">
            <v-radio-group
              v-model="inputBarcodeOption"
              :row="$vuetify.breakpoint.smAndUp"
              :column="$vuetify.breakpoint.xs"
              hide-details="auto"
              class="mt-0 pt-0"
              mandatory
            >
              <v-radio label="掃描貨物編號" value="scan"></v-radio>
              <v-radio label="輸入貨物編號" value="input"></v-radio>
              <v-radio v-if="allowAddNewCargo" label="上傳貨物編號" value="import"></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row v-if="inputBarcodeOption === 'scan'">
          <v-col>
            <v-btn
              small
              color="info"
              depressed
              :loading="loading"
              :disabled="tableLoading || loading"
              @click="scanBarCode"
            >
              掃描貨物編號
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-else-if="inputBarcodeOption === 'input'">
          <v-col cols="12">
            <v-text-field
              ref="inputBarcodeRef"
              type="text"
              label="輸入貨物編號"
              outlined
              hide-details="auto"
              v-on:keyup.enter="searchBarcodeByInput"
              :readonly="loading"
              :filled="loading"
              :append-icon="mdiMagnify"
              @click:append="searchBarcodeByInput"
              v-model="inputBarcode"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row v-else-if="inputBarcodeOption === 'import'">
          <v-col cols="12">
            <v-file-input
              :clearable="false"
              accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/csv"
              label="上傳貨物編號"
              outlined
              persistent-hint
              hide-details="auto"
              hint="只接受.xls, .xlsx, .csv檔案"
              :disabled="loading"
              @change="importExcelFile($event)"
            ></v-file-input>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        xl="6"
        lg="6"
        md="6"
        sm="12"
        cols="12"
        order-xl="2"
        order-lg="2"
        order-md="2"
        order="1"
        class="d-flex align-end"
      >
        <v-text-field
          ref="searchCargo"
          type="text"
          label="授尋貨物編號"
          outlined
          hide-details="auto"
          v-on:keyup.enter="searchCargoBarcode"
          :append-icon="mdiMagnify"
          @click:append="searchCargoBarcode"
          v-model="searchBarcode"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <Datatable
          :table-headers="tableHeaders"
          :table-data="tableData"
          :page="tablePage"
          :page-limit="tablePageLimit"
          :is-loading="tableLoading"
          :disable-pagination="tableLoading || loading"
          :page-limit-options="tablePageLimitOptions"
          :item-total="itemTotal"
          :enable-search="false"
          :enable-export="false"
          :enable-filter="false"
          :enable-date-range="false"
          :enable-clear-all="false"
          :enable-refresh="false"
          :disableSort="true"
          :submitLoading="loading"
          @options-update="onTableChange"
          @tableAction="getTableAction"
        ></Datatable>
      </v-col>
    </v-row>

    <v-row v-if="sourceFrom === 'Export'">
      <v-col cols="12">
        <v-btn depressed color="primary" @click="$emit('update:openCargo', false)">確定</v-btn>
      </v-col>
    </v-row>

    <!-- scan dialog -->
    <v-dialog v-model="openScanner" fullscreen hide-overlay>
      <v-card class="d-flex align-center justify-center">
        <StreamBarcodeReader
          v-if="openScanner"
          @decode="onStreamBarCodeDecode"
          @error="streamBarCodeError"
        ></StreamBarcodeReader>
      </v-card>
    </v-dialog>

    <!-- view dialog -->
    <Dialog
      :dialog="dialog.open"
      :text="dialog.text"
      :isDeleteDialog="dialog.isDelete"
      :max_width="dialog.width"
      v-on:close="dialogCloseEvent"
      :showCardAction="showAction"
    >
      <v-container v-if="dialog.open" fluid class="px-0">
        <v-row>
          <v-col xl="6" lg="6" md="6" sm="6" cols="12">
            <v-text-field
              type="text"
              label="客戶名稱"
              outlined
              hide-details="auto"
              v-model="cargoData.customer_name"
              readonly
              filled
            ></v-text-field>
          </v-col>
          <v-col xl="6" lg="6" md="6" sm="6" cols="12">
            <v-select
              v-model="cargoData.packing_type"
              :items="packingTypeList"
              outlined
              hide-details="auto"
              label="包裝型式"
              item-text="item-text"
              item-value="item-value"
              readonly
              filled
            ></v-select>
          </v-col>
          <v-col xl="6" lg="6" md="6" sm="6" cols="12">
            <v-text-field
              type="text"
              label="存放位置"
              outlined
              hide-details="auto"
              v-model="cargoData.location"
              readonly
              filled
            ></v-text-field>
          </v-col>
          <v-col xl="6" lg="6" md="6" sm="6" cols="12">
            <v-text-field
              type="text"
              label="庫存數量"
              outlined
              hide-details="auto"
              v-model="cargoData.boxes"
              readonly
              filled
            ></v-text-field>
          </v-col>
          <v-col xl="6" lg="6" md="6" sm="6" cols="12">
            <v-text-field
              type="text"
              label="重量"
              outlined
              hide-details="auto"
              v-model="cargoData.weight"
              readonly
              filled
            ></v-text-field>
          </v-col>
          <v-col xl="6" lg="6" md="6" sm="6" cols="12">
            <v-text-field
              type="text"
              label="長"
              outlined
              hide-details="auto"
              v-model="cargoData.cargo_length"
              readonly
              filled
            ></v-text-field>
          </v-col>
          <v-col xl="6" lg="6" md="6" sm="6" cols="12">
            <v-text-field
              type="text"
              label="闊"
              outlined
              hide-details="auto"
              v-model="cargoData.width"
              readonly
              filled
            ></v-text-field>
          </v-col>
          <v-col xl="6" lg="6" md="6" sm="6" cols="12">
            <v-text-field
              type="text"
              label="高"
              outlined
              hide-details="auto"
              v-model="cargoData.height"
              readonly
              filled
            ></v-text-field>
          </v-col>
          <v-col xl="6" lg="6" md="6" sm="6" cols="12">
            <v-text-field
              type="text"
              label="CBM"
              outlined
              hide-details="auto"
              v-model="cargoData.CBM"
              readonly
              filled
            ></v-text-field>
          </v-col>
          <v-col xl="6" lg="6" md="6" sm="12" cols="12">
            <v-text-field
              type="text"
              v-model="cargoData.ship_date"
              label="出貨日期"
              readonly
              filled
              outlined
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col xl="6" lg="6" md="6" sm="6" cols="12">
            <v-text-field
              type="text"
              label="備注"
              outlined
              hide-details="auto"
              v-model="cargoData.remark"
              readonly
              filled
            ></v-text-field>
          </v-col>
          <v-col xl="6" lg="6" md="6" sm="6" cols="12">
            <v-select
              ref="cargoStatusRef"
              v-model="cargoData.cargo_status"
              :items="cargoStatusList"
              outlined
              hide-details="auto"
              label="貨箱狀態"
              item-text="item-text"
              item-value="item-value"
              :rules="[$validate.required]"
              :readonly="!editCargoStatus"
              :filled="!editCargoStatus"
            ></v-select>
          </v-col>
        </v-row>
        <BarCodeArea
          :barCode.sync="cargoData.bar_code"
          :barcodeImage.sync="cargoData.bar_code_image"
          :scanAllow="false"
          :cargoData="cargoData"
          :cbm="cargoData.cbm"
        ></BarCodeArea>
        <PhotoArea class="mt-4" :photoList.sync="cargoPhoto" :editAllow="false"></PhotoArea>
      </v-container>
    </Dialog>
  </div>
</template>

<script>
import { mdiMagnify, mdiClose } from '@mdi/js'
import Datatable from '@/components/Datatable.vue'
import Dialog from '@/components/Dialog.vue'
import { StreamBarcodeReader } from 'vue-barcode-reader'
import BarCodeArea from '@/components/PickUp/BarCodeArea.vue'
import PhotoArea from '@/components/PickUp/PhotoArea.vue'
import * as XLSX from 'xlsx'

export default {
  name: 'ScanCargoArea',
  props: {
    openCargo: {
      type: Boolean,
      default: false,
      require: false,
    },
    cargoList: {
      type: Array,
      default: () => [],
      require: true,
    },
    newCargoBarcodeList: {
      type: Array,
      default: () => [],
      require: false,
    },
    checkCargoList: {
      // for Pickup
      type: Array,
      default: () => [],
      require: false,
    },
    loading: {
      type: Boolean,
      default: false,
      require: true,
    },
    sourceFrom: {
      // Prepare / Export / Pickup
      type: String,
      default: '',
      require: true,
    },
    allowAddNewCargo: {
      type: Boolean,
      default: false,
      require: false,
    },
    showTitle: {
      type: Boolean,
      default: true,
      require: false,
    },
    containerIndex: {
      type: Number,
      default: -1,
      require: false,
    },
    prepareCargoDatas: {
      // for Export
      type: Array,
      default: () => [],
      require: false,
    },
  },
  components: { Datatable, Dialog, StreamBarcodeReader, BarCodeArea, PhotoArea },
  data: () => ({
    mdiMagnify,
    mdiClose,
    // for table
    tableHeaders: [
      { text: 'ID', value: 'id' },
      { text: '貨物編號', value: 'bar_code_image', align: 'center' },
      { text: '貨箱圖片', value: 'cargo_image', align: 'center' },
      { text: '包裝型式', value: 'packing_type', align: 'center' },
      { text: '出貨日期', value: 'ship_date', align: 'center' },
      { text: '貨箱狀態', value: 'cargo_status' },
    ],
    tableData: [],
    tableLoading: false,
    tablePage: 1,
    tablePageLimit: 10,
    tablePageLimitOptions: [10, 20, 50],
    editCargoStatus: false,
    showAction: true,

    saveExcelBarcode: [],

    // for scan dialog
    openScanner: false,

    // for view dialog
    dialog: {
      isDelete: false,
      open: false,
      text: '',
      index: -1,
      type: '',
      width: 1200,
    },
    cargoData: {},
    cargoPhoto: [],
    cargoStatusList: [
      { 'item-text': '未到達', 'item-value': 'unarrived' },
      { 'item-text': '準備中', 'item-value': 'pending' },
      { 'item-text': '可出貨', 'item-value': 'picked' },
    ],
    cargoLocationList: [{ 'item-text': '新倉', 'item-value': '新倉' }],
    packingTypeList: [
      { 'item-text': 'CASE', 'item-value': 'CASE' },
      { 'item-text': 'CTN', 'item-value': 'CTN' },
      { 'item-text': 'PKG', 'item-value': 'PKG' },
      { 'item-text': 'PKG(桶)', 'item-value': 'PKG(桶)' },
      { 'item-text': 'PLT', 'item-value': 'PLT' },
      { 'item-text': 'PLT(桶)', 'item-value': 'PLT(桶)' },
    ],
    inputBarcodeOption: 'scan',

    searchBarcode: '',
    inputBarcode: '',
  }),
  methods: {
    async GetCargoByBarCode(code) {
      if (!this.loading) {
        code = code.trim()

        this.$emit('update:loading', true)

        if (this.sourceFrom === 'Prepare') {
          const searchIndex = this.cargoList.findIndex(obj => {
            return obj.bar_code === code
          })

          if (searchIndex !== -1) {
            this.$store.dispatch('toggleAlertMessage', {
              show: true,
              message: '你已掃描此貨箱',
              type: 'error',
              refresh: false,
              redirect: '',
            })

            this.$emit('update:loading', false)
            return
          }
        } else if (this.sourceFrom === 'Export') {
          // check whether the cargo exist in current container or other containers
          for (let i = 0; i < this.getStoreContainerData.length; i++) {
            const index = this.getStoreContainerData[i].cargo_datas.findIndex(obj => {
              return obj.bar_code === code
            })

            if (index !== -1) {
              if (this.containerIndex === i) {
                this.$store.dispatch('toggleAlertMessage', {
                  show: true,
                  message: '你已掃描此貨箱',
                  type: 'error',
                  refresh: false,
                  redirect: '',
                })
              } else {
                this.$store.dispatch('toggleAlertMessage', {
                  show: true,
                  message: '你已於其他貨櫃掃描此貨箱',
                  type: 'error',
                  refresh: false,
                  redirect: '',
                })
              }
              this.$emit('update:loading', false)
              return
            }
          }

          // check whether the cargo exist in the prepare list

          const index = this.prepareCargoDatas.findIndex(obj => {
            return obj.bar_code === code
          })

          if (index === -1) {
            this.$store.dispatch('toggleAlertMessage', {
              show: true,
              message: '備貨單沒有此貨箱',
              type: 'error',
              refresh: false,
              redirect: '',
            })

            this.$emit('update:loading', false)
            return
          }
        } else if (this.sourceFrom === 'Pickup') {
          const searchIndex = this.checkCargoList.findIndex(obj => {
            return obj.bar_code === code
          })

          if (searchIndex !== -1) {
            this.$store.dispatch('toggleAlertMessage', {
              show: true,
              message: '你已掃描此貨箱',
              type: 'error',
              refresh: false,
              redirect: '',
            })

            this.$emit('update:loading', false)
            return
          }
        }

        try {
          const result = await this.$XHR.api('get_cargo_by_barcode', { barcode: code })
          this.$func.log('-----Get Cargo By Barcode -----')
          this.$func.log(result)

          if (Array.isArray(result)) {
            // cargo does not exist
            if (!this.allowAddNewCargo) {
              // barcode does not exist
              this.$store.dispatch('toggleAlertMessage', {
                show: true,
                message: '沒有此貨箱',
                type: 'error',
                refresh: false,
                redirect: '',
              })
            } else {
              this.newCargoBarcodeList.unshift(code)
              this.cargoList.unshift({
                data_type: 'cargo_data',
                status: 'active',
                data_status: 'active',
                location: '',
                bar_code: code,
                bar_code_image: '',
                boxes: 0,
                photo: [],
                cargo_status: this.sourceFrom === 'Pickup' ? 'unarrived' : 'pending',
                remark: '',
                packing_type: '',
                weight: '',
                cargo_length: 0,
                width: 0,
                height: 0,
                CBM: 0,
                ship_date: '',
                customer_name: '',
              })

              this.updateTable()
              this.$emit('getUpdateCargo', this.cargoList, this.newCargoBarcodeList)
            }
          } else {
            // cargo exist
            const data = this.updateExistCargoDataObj(result)

            if (this.sourceFrom === 'Prepare' || this.sourceFrom === 'Pickup') {
              this.cargoList.unshift(data)
              this.updateTable()
              this.$emit('getUpdateCargo', this.cargoList, this.newCargoBarcodeList)
            } else if (this.sourceFrom === 'Export') {
              const addData = [...this.getStoreContainerData[this.containerIndex].cargo_datas]
              data.cargo_status = 'picked'

              addData.unshift(data)
              this.$store.dispatch('updateContainerDataByIndex', { index: this.containerIndex, cargoData: addData })

              this.updateTable()
            }
          }
        } catch (error) {
          this.$func.log('-----Get Cargo By Barcode fail-----')
          this.$func.log(error)
        } finally {
          this.openScanner = false
          this.$refs.inputBarcodeRef.reset()
          this.$emit('update:loading', false)
        }
      }
    },
    updateExistCargoDataObj(result) {
      const data = Object.assign({}, result)

      if (!this.$validate.DataValid(data.packing_type)) {
        data.packing_type = ''
      }

      if (!this.$validate.DataValid(data.weight)) {
        data.weight = ''
      }

      if (!this.$validate.DataValid(data.cargo_length)) {
        data.cargo_length = 0
      }

      if (!this.$validate.DataValid(data.width)) {
        data.width = 0
      }

      if (!this.$validate.DataValid(data.height)) {
        data.height = 0
      }

      if (!this.$validate.DataValid(data.CBM)) {
        data.CBM = 0
      }

      if (!this.$validate.DataValid(data.ship_date)) {
        data.ship_date = ''
      }

      if (!this.$validate.DataValid(data.customer_name)) {
        data.customer_name = ''
      }

      return data
    },
    async scanBarCode() {
      try {
        const checkCameraEnable = await navigator.mediaDevices.getUserMedia({ audio: true, video: true })

        this.openScanner = true
      } catch (error) {
        this.$func.log(error)

        this.$store.dispatch('toggleAlertMessage', {
          show: true,
          message: '此裝置沒有相機',
          type: 'error',
          refresh: false,
          redirect: '',
        })

        this.openScanner = false
      }
    },
    onStreamBarCodeDecode(e) {
      this.openScanner = false

      this.$func.log('---scan bar code---')
      this.$func.log(e)

      if (this.$validate.DataValid(e.trim())) {
        this.GetCargoByBarCode(e)
      } else {
        this.$store.dispatch('toggleAlertMessage', {
          show: true,
          message: '不可掃描空白貨物編號',
          type: 'error',
          refresh: false,
          redirect: '',
        })
      }
    },
    streamBarCodeError(error) {
      this.$store.dispatch('toggleAlertMessage', {
        show: true,
        message: error,
        type: 'error',
        refresh: false,
        redirect: '',
      })

      this.openScanner = false
    },
    searchBarcodeByInput(e) {
      if (!this.loading) {
        this.inputBarcode = this.inputBarcode.trim()
        if (this.$validate.DataValid(this.inputBarcode)) {
          this.GetCargoByBarCode(this.inputBarcode)
        }
      }
    },
    openDialog(pos) {
      if (this.sourceFrom === 'Prepare') {
        this.cargoData = Object.assign({}, this.cargoList[pos])
        this.cargoPhoto = this.cargoData.photo
        this.dialog.index = pos
        this.dialog.open = true
      } else if (this.sourceFrom === 'Export') {
        this.cargoData = Object.assign({}, this.getStoreContainerData[this.containerIndex].cargo_datas[pos])
        this.cargoPhoto = this.cargoData.photo
        this.dialog.index = pos
        this.dialog.open = true
      }
    },
    dialogCloseEvent(action) {
      if (!action) {
        this.cargoData = {}
        this.cargoPhoto = []
        this.dialog.open = false
        return
      }

      const validate = this.$refs.cargoStatusRef.validate()

      if (!validate) {
        this.$store.dispatch('toggleAlertMessage', {
          show: true,
          message: '請選擇貨箱狀態',
          type: 'error',
          refresh: false,
          redirect: '',
        })
        return
      }

      if (this.sourceFrom === 'Prepare') {
        this.cargoList[this.dialog.index].cargo_status = this.cargoData.cargo_status

        this.$emit('getUpdateCargo', this.cargoList, this.newCargoBarcodeList)

        this.cargoData = {}
        this.cargoPhoto = []
        this.updateTable()
        this.dialog.open = false
      } else if (this.sourceFrom === 'Export') {
        const cargoList = [...this.getStoreContainerData[this.containerIndex].cargo_datas]
        cargoList[this.dialog.index].cargo_status = this.cargoData.cargo_status

        this.$store.dispatch('updateContainerDataByIndex', { index: this.containerIndex, cargoData: cargoList })
        this.updateTable()

        this.cargoData = {}
        this.cargoPhoto = []
        this.dialog.open = false
      }
    },
    searchCargoBarcode(event) {
      this.searchBarcode = this.searchBarcode.trim()
      this.tablePage = 1
      this.updateTable(this.searchBarcode)
    },
    async importExcelFile(file) {
      if (!this.loading) {
        if (file !== undefined && file !== null) {
          const check = this.$validate.validateFileInput(
            file,
            [
              'application/vnd.ms-excel',
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              'text/csv',
            ],
            ['xlsx', 'xls', 'csv'],
          )

          if (this.$validate.DataValid(check)) {
            this.$store.dispatch('toggleAlertMessage', {
              show: true,
              message: check,
              type: 'error',
              refresh: false,
              redirect: '',
            })
            return ''
          }

          const data = await file.arrayBuffer()
          const workbook = XLSX.read(data)

          const barcodeJSON = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]])

          const barcodeList = barcodeJSON.map(obj => {
            return obj.barcode.toString().trim()
          })

          if (barcodeList.length > 0) {
            this.createOrGetCargo(barcodeList)
          } else {
            this.$store.dispatch('toggleAlertMessage', {
              show: true,
              message: '沒有貨物編號',
              type: 'error',
              refresh: false,
              redirect: '',
            })
          }
        }
      }
    },
    async createOrGetCargo(list) {
      // check
      // 1. cargo exist in database? get from database / create when save
      // 2. cargo exist in cargoList? do nothing / add to cargoList
      // 3. if the user has uploaded excel file before and upload another excel file again -> remove the cargo from previous excel file
      const barcodeList = [...list]
      if (!this.loading) {
        this.$emit('update:loading', true)

        try {
          // search whether the barcode exist in database
          const getExistCargoData = await this.$XHR.api('get_cargo_by_barcode_list', { barcode_list: barcodeList })
          this.$func.log('---get existing cargo---')
          this.$func.log(getExistCargoData)

          if (getExistCargoData.length > 0) {
            // check cargo exist in cargoList
            for (let i = 0; i < getExistCargoData.length; i++) {
              const searchCargo = this.cargoList.filter(obj => {
                return obj.bar_code === getExistCargoData[i].bar_code
              })

              if (searchCargo.length > 0) {
                // remove barcode from barcodeList
                const barcodeIndex = barcodeList.indexOf(getExistCargoData[i].bar_code)
                if (barcodeIndex !== -1) {
                  barcodeList.splice(barcodeIndex, 1)
                }
              }
            }
          }

          if (this.saveExcelBarcode.length > 0) {
            // the user has uploaded excel file before
            // remove the cargo from previous excel file
            for (let i = 0; i < this.saveExcelBarcode.length; i++) {
              let cargoIndex = this.cargoList.findIndex(obj => {
                return obj.bar_code === this.saveExcelBarcode[i]
              })

              if (cargoIndex !== -1) {
                this.cargoList.splice(cargoIndex, 1)
              } else {
                let newCargoIndex = this.newCargoBarcodeList.indexOf(this.saveExcelBarcode[i])
                if (newCargoIndex !== -1) {
                  this.newCargoBarcodeList.splice(newCargoIndex)
                }
              }
            }
          }

          // add to cargoList

          for (let i = 0; i < barcodeList.length; i++) {
            let existIndex = getExistCargoData.findIndex(obj => {
              return obj.bar_code === barcodeList[i]
            })

            let data = null
            if (existIndex !== -1) {
              data = this.updateExistCargoDataObj(getExistCargoData[existIndex])
            } else {
              data = {
                data_type: 'cargo_data',
                status: 'active',
                data_status: 'active',
                location: '',
                bar_code: barcodeList[i],
                bar_code_image: '',
                boxes: 0,
                photo: [],
                cargo_status: 'unarrived',
                remark: '',
                packing_type: '',
                weight: '',
                cargo_length: 0,
                width: 0,
                height: 0,
                CBM: 0,
                ship_date: '',
                customer_name: '',
              }

              this.newCargoBarcodeList.unshift(barcodeList[i])
            }

            this.cargoList.unshift(data)
          }

          this.saveExcelBarcode = [...barcodeList]

          this.updateTable()
          this.$emit('getUpdateCargo', this.cargoList, this.newCargoBarcodeList)
        } catch (error) {
          this.$func.log('---create or get cargo fail---')
          this.$func.log(error)
        } finally {
          this.$emit('update:loading', false)
        }
      }
    },
    updateTable(barcode = null) {
      const dataShouldShow = []
      let filterActiveStatus = []
      if (this.sourceFrom === 'Prepare' || this.sourceFrom === 'Pickup') {
        filterActiveStatus = this.cargoList.filter(obj => {
          return obj.data_status === 'active' && this.$validate.DataValid(barcode) ? obj.bar_code === barcode : true
        })
      } else if (this.sourceFrom === 'Export') {
        filterActiveStatus = this.getStoreContainerData[this.containerIndex].cargo_datas.filter(obj => {
          return obj.data_status === 'active' && this.$validate.DataValid(barcode) ? obj.bar_code === barcode : true
        })
      }

      if (filterActiveStatus.length > 0) {
        let end = 0

        if (this.itemTotal <= this.tablePageLimit) {
          end = this.itemTotal
        } else {
          if (this.tablePage * this.tablePageLimit >= this.itemTotal) {
            end = this.itemTotal
          } else {
            end = this.tablePage * this.tablePageLimit
          }
        }

        const start = (this.tablePage - 1) * this.tablePageLimit

        for (let i = start; i < end; i++) {
          const data = Object.assign({}, filterActiveStatus[i])
          data.photo = filterActiveStatus[i].photo /* .length > 0 ? filterActiveStatus[i].photo[0] : '' */
          dataShouldShow.push(data)
        }
      }

      this.tableData = dataShouldShow
    },
    getTableAction(type, cargoIndex) {
      const pos = this.tablePage - 1 + cargoIndex
      if (type === 'edit' || type === 'view') {
        this.dialog.cargoIndex = pos
        this.dialog.text = type === 'edit' ? '修改貨箱狀態' : '查看貨箱'
        this.dialog.type = type
        this.showAction = type === 'edit'
        this.openDialog(pos)
      } else if (type === 'remove') {
        if (this.sourceFrom === 'Prepare' || this.sourceFrom === 'Pickup') {
          if (!this.$validate.DataValid(this.cargoList[pos].id)) {
            const cargoIndex = this.newCargoBarcodeList.indexOf(this.cargoList[pos].bar_code)

            if (cargoIndex !== -1) {
              this.newCargoBarcodeList.splice(cargoIndex, 1)
            }
          }

          this.cargoList.splice(pos, 1)
          this.updateTable()
          this.$emit('getUpdateCargo', this.cargoList, this.newCargoBarcodeList)
        } else if (this.sourceFrom === 'Export') {
          const cargoList = [...this.getStoreContainerData[this.containerIndex].cargo_datas]
          cargoList.splice(cargoIndex, 1)

          this.$store.dispatch('updateContainerDataByIndex', { index: this.containerIndex, cargoData: cargoList })
          this.updateTable()
        }
      } else if (type === 'pdf') {
        /// TODO:
      }
    },
    onTableChange(options) {
      if (options.itemsPerPage !== this.tablePageLimit) {
        this.tablePage = 1 // reset to first page if view options changed
      } else {
        this.tablePage = options.page
      }

      this.tablePageLimit = options.itemsPerPage
      this.updateTable()
    },
  },
  watch: {
    cargoList: {
      deep: true,
      immediate: true,
      handler(newVal) {
        if (newVal.length > 0) {
          this.updateTable()
        }
      },
    },
  },
  computed: {
    itemTotal() {
      let filterItem = []
      if (this.sourceFrom === 'Prepare' || this.sourceFrom === 'Pickup') {
        filterItem = this.cargoList.filter(obj => {
          return obj.data_status === 'active' && this.$validate.DataValid(this.searchBarcode)
            ? obj.bar_code === this.searchBarcode
            : true
        })
      } else if (this.sourceFrom === 'Export') {
        filterItem = this.getStoreContainerData[this.containerIndex].cargo_datas.filter(obj => {
          return obj.data_status === 'active' && this.$validate.DataValid(this.searchBarcode)
            ? obj.bar_code === this.searchBarcode
            : true
        })
      }

      return filterItem.length
    },
    getStoreContainerData() {
      return this.$store.getters.containerDatas
    },
  },
  created() {
    if (this.sourceFrom === 'Pickup') {
      this.editCargoStatus = false

      this.tableHeaders.push({
        text: '',
        value: 'actionViewDelete',
        sortable: false,
        align: 'end',
      })
    } else if (this.sourceFrom === 'Prepare') {
      this.editCargoStatus = false

      this.tableHeaders.push({
        text: '',
        value: 'actionViewViewDelete',
        sortable: false,
        align: 'end',
      })
    } else if (this.sourceFrom === 'Export') {
      this.editCargoStatus = true
      this.tableHeaders.push({
        text: '',
        value: 'actionViewEditDeletePDF',
        sortable: false,
        align: 'end',
      })
    }
  },
}
</script>
