<template>
  <div>
    <v-row>
      <v-col class="d-flex align-center">
        <h4>相片</h4>
        <v-btn
          class="ml-3"
          small
          color="info"
          depressed
          :loading="dialogLoading"
          :disabled="!editAllow"
          @click="triggerUpload('takePhoto')"
          >拍照</v-btn
        >
        <v-btn
          class="ml-3"
          small
          color="accent"
          depressed
          :loading="dialogLoading"
          @click="triggerUpload('uploadImage')"
          :disabled="!editAllow"
          >上傳</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="photo-container">
          <v-file-input
            v-show="false"
            id="takePhoto"
            accept="image/jpeg,image/png"
            outlined
            capture="environment"
            @change="uploadPhoto($event)"
          ></v-file-input>
          <v-file-input
            v-show="false"
            id="uploadImage"
            accept="image/jpeg,image/png"
            outlined
            @change="uploadPhoto($event)"
          ></v-file-input>
          <div v-for="(photo, index) in photoList" :key="index">
            <div class="close-btn" @click="removeImage(index)" v-if="editAllow"></div>
            <v-img @click="previewImage(index)" :src="`${$mediaPath}${photo}`" cover height="100"> </v-img>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'PhotoArea',
  props: {
    photoList: {
      type: Array,
      default: () => [],
      require: true,
    },
    dialogLoading: {
      type: Boolean,
      default: false,
      require: true,
    },
    editAllow: {
      type: Boolean,
      default: true,
      require: false,
    },
  },
  data: () => ({}),
  methods: {
    async uploadPhoto(file) {
      this.$emit('update:dialogLoading', true)
      const result = await this.getUploadFile(file)

      if (this.$validate.DataValid(result)) {
        this.photoList.push(result)
      }

      this.$emit('update:dialogLoading', false)
      this.$emit('update:photoList', this.photoList)
    },
    previewImage(index) {
      window.open(this.$mediaPath + this.photoList[index])
    },
    removeImage(index) {
      this.photoList.splice(index, 1)
      this.$emit('update:photoList', this.photoList)
    },
  },
}
</script>


<style lang="scss" scoped>
.photo-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, 100px);
  grid-column-gap: 20px;
  grid-row-gap: 20px;

  & > div {
    position: relative;

    &:hover {
      cursor: pointer;
    }

    & .v-image {
      border: 1px solid #e1e1e1;
    }

    & .close-btn {
      position: absolute;
      width: 20px;
      height: 20px;
      top: -5px;
      right: -10px;
      background-color: lightgray;
      border-radius: 50px;
      z-index: 2;

      &::before,
      &::after {
        content: '';
        position: absolute;
        height: 13px;
        width: 2px;
        background-color: white;
        top: 4px;
        left: 9px;
      }

      &::before {
        transform: rotate(45deg);
      }

      &::after {
        transform: rotate(-45deg);
      }
    }
  }
}
</style>
