<template>
  <div>
    <v-row class="mt-3">
      <v-col>
        <h4>貨物編號</h4>
      </v-col>
    </v-row>
    <v-row class="mt-0">
      <v-col>
        <v-radio-group v-model="inputBarcodeOption" row hide-details="auto" class="mt-0 pt-0" mandatory>
          <v-radio label="掃描貨物編號" value="scan"></v-radio>
          <v-radio label="輸入貨物編號" value="input"></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row v-if="inputBarcodeOption === 'scan'">
      <v-col xl="6" lg="6" md="6" sm="6" cols="12">
        <v-text-field
          type="text"
          label="貨物編號"
          outlined
          hide-details="auto"
          :value="barCode"
          :rules="[$validate.required]"
          readonly
          filled
        ></v-text-field>
      </v-col>
      <v-col xl="6" lg="6" md="6" sm="6" cols="12" class="d-flex align-center">
        <v-btn depressed color="primary" @click="scanBarCode" :loading="loading" :disabled="!scanAllow">掃描</v-btn>
        <v-btn
          class="ml-2"
          depressed
          :disabled="!$validate.DataValid(barCode)"
          color="info"
          @click="downloadBarcode"
          :loading="loading"
          >下載</v-btn
        >
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col xl="6" lg="6" md="6" sm="6" cols="12">
        <v-text-field
          type="text"
          label="貨物編號"
          outlined
          hide-details="auto"
          :rules="[$validate.required]"
          :value="barCode"
          :readonly="!scanAllow || loading"
          :filled="!scanAllow || loading"
          v-model="inputBarcode"
          @change="e => (inputBarcode = e)"
        ></v-text-field>
      </v-col>
      <v-col xl="6" lg="6" md="6" sm="6" cols="12" class="d-flex align-center">
        <v-btn depressed color="primary" @click="confirmBarcodeInput" :disabled="!scanAllow" :loading="loading"
          >確定</v-btn
        >
        <v-btn
          class="ml-2"
          depressed
          :disabled="!$validate.DataValid(barCode)"
          color="info"
          @click="downloadBarcode"
          :loading="loading || searching"
          >下載</v-btn
        >
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <VueBarcode id="barcode-svg" :value="barCode" tag="svg"></VueBarcode>
        <VueBarcode v-show="false" id="barcode-image" :value="barCode" tag="img"></VueBarcode>
      </v-col>
    </v-row>

    <v-row id="download-bar-code">
      <v-col>
        <v-row class="ma-0 area-1">
          <v-col cols="6" class="customer-name pt-0 pr-0 pb-0">
            {{ cargoData.customer_name }}
          </v-col>
          <v-col cols="6" class="bar-code-area pt-0 pl-0 pb-0"> </v-col>
        </v-row>
        <v-row class="ma-0 area-2">
          <v-col cols="12" class="bar-code-text">
            {{ barCode }}
          </v-col>
        </v-row>
        <v-row class="ma-0 area-3">
          <v-col>
            <table id="data-area" border="1"></table>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-dialog v-model="openScanner" fullscreen hide-overlay>
      <v-card class="d-flex align-center justify-center">
        <StreamBarcodeReader
          v-if="openScanner"
          @decode="onStreamBarCodeDecode"
          @error="streamBarCodeError"
        ></StreamBarcodeReader>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { StreamBarcodeReader } from 'vue-barcode-reader'
import VueBarcode from '@chenfengyuan/vue-barcode'
import html2pdf from 'html2pdf.js'

export default {
  name: 'BarCodeArea',
  props: {
    barCode: {
      type: String,
      default: '',
      require: true,
    },
    barcodeImage: {
      type: String,
      default: '',
      require: true,
    },
    scanAllow: {
      type: Boolean,
      default: true,
      require: false,
    },
    cargoIDList: {
      type: Array,
      default: () => [],
      require: false,
    },
    cargoData: {
      type: Object,
      default: () => {},
      require: false,
    },
    cbm: {
      type: Number,
      default: 0,
      require: false,
    },
    loading: {
      type: Boolean,
      default: false,
      require: true,
    },
  },
  components: { StreamBarcodeReader, VueBarcode },
  data: () => ({
    openScanner: false,
    openUploader: false,
    inputBarcodeOption: 'scan',
    inputBarcode: '',
    saveBarcode: '',
    searching: false,
  }),
  methods: {
    async scanBarCode() {
      try {
        const checkCameraEnable = await navigator.mediaDevices.getUserMedia({ audio: true, video: true })

        this.openScanner = true
      } catch (error) {
        this.$func.log(error)

        this.$store.dispatch('toggleAlertMessage', {
          show: true,
          message: '此裝置沒有相機',
          type: 'error',
          refresh: false,
          redirect: '',
        })

        this.openScanner = false
      }
    },
    async checkBarcodeExist(barcode) {
      try {
        const result = await this.$XHR.api('get_cargo_by_barcode', { barcode })
        this.$func.log('-----Get Cargo By Barcode -----')
        this.$func.log(result)

        if (Array.isArray(result)) {
          // barcode does not exist
          return false
        } else {
          return true
        }
      } catch (error) {
        this.$func.log('-----Get Cargo By Barcode fail-----')
        this.$func.log(error)

        return false
      }
    },
    downloadBarcode() {
      const dom = document.querySelector('#download-bar-code').cloneNode(true)
      dom.style.display = 'block'

      const barCodeArea = dom.querySelector('.bar-code-area')
      barCodeArea.innerHTML = ''

      // barcode svg
      const svg = document.querySelector('#barcode-svg').cloneNode(true)
      // remove the barcode text
      svg.lastChild.lastChild.remove()
      barCodeArea.appendChild(svg)

      // data area
      const table = dom.querySelector('#data-area')
      table.innerHTML = ''

      const tableArr = [
        { text: '重量', value: this.cargoData.weight },
        { text: '長', value: this.cargoData.cargo_length },
        { text: '闊', value: this.cargoData.width },
        { text: '高', value: this.cargoData.height },
        { text: 'CBM', value: this.cbm },
      ]

      let count = 0
      let tableRow = document.createElement('tr')

      for (let i = 0; i < tableArr.length; i++) {
        if (this.$validate.DataValid(tableArr[i].value)) {
          if (typeof tableArr[i].value === 'number' && tableArr[i].value === 0) {
            continue
          }

          const tableCell = document.createElement('td')
          tableCell.style.width = '25%'
          tableCell.style.textAlign = 'center'
          tableCell.style.paddingTop = '10px'
          tableCell.style.paddingBottom = '10px'

          tableCell.innerHTML = `<div style="font-size: 1.5rem;
            line-height: 2rem;
            font-weight: bold;">${tableArr[i].text}</div><div style="font-size: 1.125rem;
            line-height: 1.5rem;
            font-weight: bold;
            margin-top: 5px;">${tableArr[i].value}</div>`
          tableRow.appendChild(tableCell)
          count++

          if (count % 4 === 0 && count !== 0) {
            table.appendChild(tableRow)
            tableRow = document.createElement('tr')
          }
        }
      }

      if (count !== 0) {
        const mod = count % 4
        if (mod !== 0) {
          const remaining = 4 - mod

          for (let i = 0; i < remaining; i++) {
            const tableCell = document.createElement('td')
            tableRow.appendChild(tableCell)
          }

          table.appendChild(tableRow)
        }

        table.style.display = 'table'
      } else {
        table.style.display = 'none'
      }

      html2pdf(dom, {
        margin: 1,
        filename: `${this.barCode}.pdf`,
        jsPDF: { unit: 'mm', format: 'A4', orientation: 'landscape' },
      })
    },
    onStreamBarCodeDecode(e) {
      this.openScanner = false

      this.$func.log('---scan bar code---')
      this.$func.log(e)

      if (this.$validate.DataValid(e.trim())) {
        this.updateInputBarcode(e)
      } else {
        this.$store.dispatch('toggleAlertMessage', {
          show: true,
          message: '不可掃描空白貨物編號',
          type: 'error',
          refresh: false,
          redirect: '',
        })
      }
    },
    streamBarCodeError(error) {
      this.$store.dispatch('toggleAlertMessage', {
        show: true,
        message: error,
        type: 'error',
        refresh: false,
        redirect: '',
      })

      this.openScanner = false
    },
    confirmBarcodeInput() {
      this.updateInputBarcode(this.inputBarcode.trim())
    },
    async updateInputBarcode(barcode) {
      this.$emit('update:loading', true)
      if (this.$validate.DataValid(barcode)) {
        if (barcode !== this.saveBarcode) {
          let barcodeExist = false

          // 1. check whether the cargo in this container contains the same barcode
          barcodeExist = this.cargoIDList.includes(barcode)
          if (!barcodeExist) {
            // 2. check whether the cargo in all the container contains the same barcode
            barcodeExist = await this.checkBarcodeExist(barcode)
          }

          if (barcodeExist) {
            this.$store.dispatch('toggleAlertMessage', {
              show: true,
              message: '貨物編號已存在於此貨櫃/其他貨櫃',
              type: 'error',
              refresh: false,
              redirect: '',
            })

            this.inputBarcode = this.saveBarcode
          } else {
            this.$emit('update:barCode', barcode)

            /*             setTimeout(() => {
              this.uploadBarCode('barcode-image')
            }, 500) */
          }
        }
      } else {
        this.$store.dispatch('toggleAlertMessage', {
          show: true,
          message: '不可掃描/輸入空白貨物編號',
          type: 'error',
          refresh: false,
          redirect: '',
        })
      }

      this.$emit('update:loading', false)
    },
    async uploadBarCode(id) {
      const img = document.getElementById(id)
      if (img) {
        const file = await this.urlToFile(img.src, 'barcode.jpg', 'image/jpeg')
        const result = await this.getUploadFile(file)
        if (this.$validate.DataValid(result)) {
          this.$emit('update:barcodeImage', result)
        }
      }
    },
    async urlToFile(url, filename, mimeType) {
      // https://thewebdev.info/2022/05/07/how-to-convert-base64-string-to-javascript-file-object-like-as-from-file-input-form/
      const res = await fetch(url)
      const buf = await res.arrayBuffer()
      return new File([buf], filename, { type: mimeType })
    },
  },
  watch: {
    barCode: function (newVal) {
      this.saveBarcode = newVal
    },
  },
  created() {
    this.inputBarcode = this.barCode
    this.saveBarcode = this.barCode
  },
}
</script>

<style lang="scss" scoped>
#download-bar-code {
  display: none;
  margin: 20px;

  .area-1,
  .area-2,
  .area-3 {
    display: flex;
    align-items: center;
  }

  .area-1 {
    height: 40mm;
  }

  .area-2 {
    height: 95mm;
  }

  .area-3 {
    height: 45mm;
  }

  .customer-name {
    font-size: 4rem;
    line-height: 5rem;
    font-weight: bold;
  }

  .bar-code-area {
    display: flex;
    justify-content: flex-end;
  }

  .bar-code-text {
    font-size: 6rem;
    line-height: 7rem;
    font-weight: bold;
    text-align: center;
  }
}

#data-area {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}
</style>